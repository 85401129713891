
export default {
  name: 'footer-us',
  components: {},
  data() {
    return {
      privacyPolicy: 'https://terms.hirey.com/en/privacy_policy',
      platformLinks: [
        {
          name: 'twitter',
          defaultIcon: require('../assets/ic_twitter_nor@2x.png'),
          selectedIcon: require('../assets/ic_twitter_select@2x.png'),
          url: 'https://x.com/Hirey_app',
          isHovered: false,
        },
        {
          name: 'linkin',
          defaultIcon: require('../assets/ic_in_nor@2x.png'),
          selectedIcon: require('../assets/ic_in_select@2x.png'),
          url: 'https://www.linkedin.com/company/89732496/admin/',
          isHovered: false,
        },
        {
          name: 'facebook',
          defaultIcon: require('../assets/ic_facebook_nor@2x.png'),
          selectedIcon: require('../assets/ic_facebook_select@2x.png'),
          url: 'https://www.facebook.com/HireyApp-101849606067445',
          isHovered: false,
        },
        {
          name: 'ins',
          defaultIcon: require('../assets/ic_ins_nor@2x.png'),
          selectedIcon: require('../assets/ic_ins_select@2x.png'),
          url: 'https://www.instagram.com/hireyapp/',
          isHovered: false,
        },
        {
          name: 'tiktok',
          defaultIcon: require('../assets/ic_tiktok_nor@2x.png'),
          selectedIcon: require('../assets/ic_tiktok_select@2x.png'),
          url: 'https://www.tiktok.com/@hireyapp?_t=8WmyePq3q9a&_r=1',
          isHovered: false,
        },
      ],
      footerLinks: [
        {
          title: "Hirey",
          width: "107px",
          links: [
            {
              label: "For Employers",
              router: "/recruiter"
            },
            {
              label: "For Job Seekers",
              router: "/jobseeker"
            },
            {
              label: "Blog",
              router: "/blog/list"
            },
            {
              label: "Reviews",
              router: "/hirey-reviews"
            },
            {
              label: "Reviews Platform",
              router: "/hirey-app-reviews"
            },
          ]
        },
        {
          title: "Support",
          width: "178px",
          links: [
            {
              label: "Tel: +1 (415) 992-8410",
            },
            {
              prefix: "Email:",
              label: "support@hirey.com",
              url: "mailto:support@hirey.com"
            },
          ]
        },
        {
          title: "Legal",
          width: "129px",
          links: [
            {
              label: "Privacy Policy",
              url: this.privacyPolicy,
            },
            {
              label: "Terms & Conditions",
              url: 'https://terms.hirey.com/en/terms_conditions',
            },
            {
              label: "Terms of Service",
              url: 'https://terms.hirey.com/en/service_agreement/',
            },
          ]
        },
      ]
    }
  },
  async fetch() {
  },
  created() {
  },
  mounted() {

  },
  methods: {
    logoBth() {
      this.$router.push('/')
    },
    clickLink(link) {
      if (link.router) {
        this.$router.push(link.router)
      } else if (link.url) {
        window.open(link.url, '_blank')
      }
    }
  }
}
