
import { Loading, Message } from 'element-ui';
import {
  send as logSend
} from 'smith-log'

export default {
  props: {
    page: String
  },
  data() {
    return {
      options: [],
      locationOptions: [],
      loading: false,

      jobVisible: '',
      locationVisible: '',
      jobValue:  '',
      locationValue: '',
      cityId: '',
      query: '',
      queryLocationValue: '',
      noData: false,
      desc: ''
    }
  },
  methods: {
    onQueryClear() {
      document.activeElement.blur()
    },
    onQueryChange() {
      this.$root.$emit('changeQuery', this.jobValue, this.cityId)
    },
    async remoteMethod(query, callback) {
      if (query !== '') {
        this.loading = true;
        const res = await this.$api.jobseeker.searchJob(query)
        this.loading = false;
        callback(res.data.data.map(i => {
          return {
            value: i.dictItemName
          }
        }))
        // this.options = res.data.data
      } else {
        callback([])
        // this.options = [];
      }
    },

    locationRemoteMethod(query, init) {
      if (query !== '') {
        this.query = query
        this.loading = true;
        this.cityId = ''
        this.$api.jobseeker.searchPlace(query).then(res => {
          this.loading = false;
          this.locationOptions = res.data.data.predictions

          const {
            locationValue = ''
          } = this.$route.query
          if(locationValue !== '' && init) {
            this.locationValue = Number(locationValue)
            // this.onLocationSelect()
          }
          if(this.locationOptions.length > 0 && !init) {
            this.onLocationSelect(0)
            this.queryLocationValue = 0
          } else {
            this.noData = true
          }
        })
      } else {
        this.locationOptions = [];
        this.noData = false
      }
    },

    onJobChange(visible) {
      this.jobVisible = visible
    },
    onLocationChange(visible) {
      this.locationVisible = visible
    },
    async onLocationSelect(index) {
      if(index !== '' || this.locationValue !== '') {
        let loadingInstance = Loading.service({
          background: 'transparent'
        });

        const cityObj = this.locationOptions[index !== undefined ? index : this.locationValue]
        const params = {
          place_id: cityObj.place_id,
          main_text: cityObj.structured_formatting.main_text,
          description: cityObj.description
        }
        const res = await this.$api.jobseeker.newDetails(params)

        const data = res?.data?.data?.sysDept

        this.cityId = data.deptId
        this.desc = cityObj.description

        loadingInstance.close();

        this.$root.$emit('changeQuery', this.jobValue, this.cityId)
      }
    },
    onClick() {
      // if(this.cityId === '' && this.jobValue === '') {
      //   Message.error('Enter a job title or location to start a search')
      // } else
      if(this.page === 'search') {
        logSend('NU', 'e_ca_website_search_succeed', {
          search_information: this.jobValue,
          city_id: this.cityId,
          page_id: 'p_ca_website_search'
        })
        // console.log('1111111111')
        // if (this.$route.path.indexOf('/apply') > -1) {
        const qs = this.jobValue
        const cityId  = this.cityId || ''
        const source = this.page === 'job' ? 'p_ca_website_job_detail' : 'p_ca_website'
        const description = this.locationValue !== '' ? this.locationOptions[this.locationValue].description : this.desc

        location.href = `/jobseeker/search?qs=${qs}&cityId=${cityId}&source=${source}&description=${description}`
        // }
        // this.$root.$emit('search', this.jobValue, this.cityId)
      } else {
        const qs = this.jobValue
        const cityId  = this.cityId || ''

        const source = this.page === 'job' ? 'p_ca_website_job_detail' : 'p_ca_website'

        if(this.page === 'job') {
          logSend('NU', 'e_ca_website_search_succeed', {
            search_information: qs,
            city_id: cityId,
            page_id: 'p_ca_website_job_detail'
          })
        } else {
          logSend('NU', 'e_ca_website_search_succeed', {
            search_information: qs,
            city_id: cityId,
            page_id: 'p_ca_website'
          })
        }

        const description = this.locationValue !== '' ? this.locationOptions[this.locationValue].description : this.desc

        location.href = `/jobseeker/search?qs=${qs}&cityId=${cityId}&source=${source}&description=${description}`
      }
    },
    onClear() {
      this.cityId = ''
    }
  },
  mounted() {
    let {
      qs = '',
      cityId,
      description
    } = this.$route.query

    const queryString = this.$route?.params?.query
    let arr = []
    if(queryString) {
      arr = queryString.split('-')
    }
    qs = qs || arr[0] || ''
    cityId = cityId || arr[1] || ''
    description = description || arr[2] || ''
    this.jobValue = qs
    this.cityId = cityId
    const _description = description

    if(_description!== '' && _description !== undefined && _description !== null) {
      // this.locationRemoteMethod(query, true)
      this.locationOptions = [{
        description: _description
      }]
      this.locationValue = 0
    }
  }
}
